import {RikData} from "../rik-data";

export class ArrivalDeparture {
  readonly arrival_date: string;
  readonly arrival_start: string;
  readonly arrival_end: string;
  readonly departure_date: string;
  readonly departure_end: string;
  constructor(data:any) {
    const rikData = new RikData(data);

    this.arrival_date = rikData.requireExists('arrival_date');
    this.arrival_start = rikData.requireExists('arrival_start');
    this.arrival_end = rikData.requireExists('arrival_end');
    this.departure_date = rikData.requireExists('departure_date');
    this.departure_end = rikData.requireExists('departure_end');
  }
}
