import {Component, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from 'src/app/core/services/navigation.service';
import {RbRoutingService} from 'src/app/core/services/rb-routing.service';
import {Booking} from 'src/app/domain/booking/booking';
import {KlarnaStatus} from 'src/app/shared/klarna/klarna-status';
import {MY_BOOKING_ROUTES} from '../../my-booking-routes';

@Component({
  selector: 'mybooking-info-box',
  templateUrl: './mybooking-info-box.component.html',
})
export class InfoBoxComponent {
  @Input()
  public booking: Booking = Booking.empty();

  constructor(
    private translateService: TranslateService,
    private navigate: NavigationService,
    private rbRoutingService: RbRoutingService,
    private route: ActivatedRoute
  ) {
  }

  get heading(): string {
    if (this.booking?.hasArrived || this.booking?.isCancelled) {
      const state = this.translateService.instant(this.booking?.state.code);

      return this.translateService.instant('MYBOOKING.INFO.HEADING.ARRIVED', {
        vehicleInfo: this.booking?.vehicle.getSummary(),
        date: this.booking?.displayReservedDateWithYear,
        state: state,
      });
    }

    if (this.booking?.isStonechip) {
      return this.translateService.instant(
        'MYBOOKING.INFO.HEADING.CONFIRMED_STONECHIP',
        {
          vehicleInfo: this.booking?.vehicle.getSummary(),
          date: this.booking?.displayReservedDateWithYear,
          arrive: this.booking?.reserved_time,
        }
      );
    }
    return this.translateService.instant('MYBOOKING.INFO.HEADING.CONFIRMED', {
      vehicleInfo: this.booking?.vehicle.getSummary(),
      date: this.booking?.displayReservedDateWithYear,
      opensAt: this.booking?.workshop.opens_at,
      arriveBefore: this.booking?.workshop.arrive_before,
    });
  }

  get arrivalTimeExplanation(): string {
    if (!this.booking) {
      return '';
    }
    if (this.booking.isStonechip) {
      return this.translateService.instant('ARRIVE_REPAIR_TEXT', {
        arrive: this.booking.reserved_time,
      });
    }

    if (this.booking.arrives_day_before) {
      return this.translateService.instant('ARRIVE_DAY_BEFORE_TEXT', {
        arriveAfter: this.booking.arrival_departure?.arrival_start,
        closesAt: this.booking.arrival_departure?.arrival_end,
      });
    }

    if (this.booking.isMorningExpress) {
      return this.translateService.instant('ARRIVE_MORNING_EXPRESS_TEXT', {
        opensAt: this.booking.arrival_departure?.arrival_start,
        arriveBefore: this.booking.arrival_departure?.arrival_end,
      });
    }

    if (this.booking.isAfternoonExpress) {
      return this.translateService.instant('ARRIVE_AFTERNOON_EXPRESS_TEXT', {
        arriveBefore: this.booking.arrival_departure?.arrival_end,
      });
    }

    return this.translateService.instant('ARRIVE_REPLACE_TEXT', {
      opensAt: this.booking.arrival_departure?.arrival_start,
      arriveBefore: this.booking.arrival_departure?.arrival_end,
    });
  }

  get showInfoSectionExtras(): boolean {
    return (
      !!this.booking &&
      !this.booking.isConfirmedOrPrev() &&
      this.booking.hasAnyExtras()
    );
  }

  get extrasText(): string {
    if (!this.booking) {
      return '';
    }
    const title = this.booking.extrasSummary;
    title.translateWith(this.translateService);
    return title.text;
  }

  get isPaymentCompleted(): boolean {
    return (
      this.booking != null &&
      this.booking.klarna_status === KlarnaStatus.COMPLETED
    );
  }

  navigateToWorkshopDetails() {
    this.navig(MY_BOOKING_ROUTES.DETAILS);
  }

  rebook() {
    this.navig(MY_BOOKING_ROUTES.REBOOK);
  }

  navig(childPath: string) {
    this.rbRoutingService.navChild(this.route, childPath);
  }
}
